export const POST_LIST_REQUEST = "POST_LIST_REQUEST";
export const POST_LIST_SUCCESS = "POST_LIST_SUCCESS";
export const POST_LIST_FAIL = "POST_LIST_FAIL";

export const POST_DETAILS_REQUEST = "POST_DETAILS_REQUEST";
export const POST_DETAILS_SUCCESS = "POST_DETAILS_SUCCESS";
export const POST_DETAILS_FAIL = "POST_DETAILS_FAIL";

export const POST_CREATE_REQUEST = "POST_CREATE_REQUEST";
export const POST_CREATE_SUCCESS = "POST_CREATE_SUCCESS";
export const POST_CREATE_FAIL = "POST_CREATE_FAIL";
export const POST_CREATE_RESET = "POST_CREATE_RESET";

export const POST_LIKE_REQUEST = "POST_LIKE_REQUEST";
export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";
export const POST_LIKE_FAIL = "POST_LIKE_FAIL";
export const POST_LIKE_RESET = "POST_LIKE_RESET";

export const POST_COMMENTS_REQUEST = "POST_COMMENTS_REQUEST";
export const POST_COMMENTS_SUCCESS = "POST_COMMENTS_SUCCESS";
export const POST_COMMENTS_FAIL = "POST_COMMENTS_FAIL";
export const POST_COMMENTS_RESET = "POST_COMMENTS_RESET";

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";
export const ADD_COMMENT_RESET = "ADD_COMMENT_RESET";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL";

export const EDIT_COMMENT_REQUEST = "EDIT_COMMENT_REQUEST";
export const EDIT_COMMENT_SUCCESS = "EDIT_COMMENT_SUCCESS";
export const EDIT_COMMENT_FAIL = "EDIT_COMMENT_FAIL";

export const MY_POST_LIST_REQUEST = "MY_POST_LIST_REQUEST";
export const MY_POST_LIST_SUCCESS = "MY_POST_LIST_SUCCESS";
export const MY_POST_LIST_FAIL = "MY_POST_LIST_FAIL";

export const LIKED_POST_LIST_REQUEST = "LIKED_POST_LIST_REQUEST";
export const LIKED_POST_LIST_SUCCESS = "LIKED_POST_LIST_SUCCESS";
export const LIKED_POST_LIST_FAIL = "LIKED_POST_LIST_FAIL";

export const POST_DELETE_REQUEST = "POST_DELETE_REQUEST";
export const POST_DELETE_SUCCESS = "POST_DELETE_SUCCESS";
export const POST_DELETE_FAIL = "POST_DELETE_FAIL";

export const POST_UPDATE_REQUEST = "POST_UPDATE_REQUEST";
export const POST_UPDATE_SUCCESS = "POST_UPDATE_SUCCESS";
export const POST_UPDATE_FAIL = "POST_UPDATE_FAIL";

export const SUBCOMMENT_REQUEST = "SUBCOMMENT_REQUEST";
export const SUBCOMMENT_SUCCESS = "SUBCOMMENT_SUCCESS";
export const SUBCOMMENT_FAIL = "SUBCOMMENT_FAIL";

export const ADD_SUBCOMMENT_REQUEST = "ADD_SUBCOMMENT_REQUEST";
export const ADD_SUBCOMMENT_SUCCESS = "ADD_SUBCOMMENT_SUCCESS";
export const ADD_SUBCOMMENT_FAIL = "ADD_SUBCOMMENT_FAIL";
export const ADD_SUBCOMMENT_RESET = "ADD_SUBCOMMENT_RESET";

export const DELETE_SUBCOMMENT_REQUEST = "DELETE_SUBCOMMENT_REQUEST";
export const DELETE_SUBCOMMENT_SUCCESS = "DELETE_SUBCOMMENT_SUCCESS";
export const DELETE_SUBCOMMENT_FAIL = "DELETE_SUBCOMMENT_FAIL";

export const EDIT_SUBCOMMENT_REQUEST = "EDIT_SUBCOMMENT_REQUEST";
export const EDIT_SUBCOMMENT_SUCCESS = "EDIT_SUBCOMMENT_SUCCESS";
export const EDIT_SUBCOMMENT_FAIL = "EDIT_SUBCOMMENT_FAIL";
