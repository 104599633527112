export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_SUCCESS = "CATEGORIES_LIST_SUCCESS";
export const CATEGORIES_LIST_FAIL = "CATEGORIES_LIST_FAIL";

export const CATEGORIES_DELETE_REQUEST = "CATEGORIES_DELETE_REQUEST";
export const CATEGORIES_DELETE_SUCCESS = "CATEGORIES_DELETE_SUCCESS";
export const CATEGORIES_DELETE_FAIL = "CATEGORIES_DELETE_FAIL";

export const CATEGORIES_ADD_REQUEST = "CATEGORIES_ADD_REQUEST";
export const CATEGORIES_ADD_SUCCESS = "CATEGORIES_ADD_SUCCESS";
export const CATEGORIES_ADD_FAIL = "CATEGORIES_ADD_FAIL";

export const CATEGORIES_EDIT_REQUEST = "CATEGORIES_EDIT_REQUEST";
export const CATEGORIES_EDIT_SUCCESS = "CATEGORIES_EDIT_SUCCESS";
export const CATEGORIES_EDIT_FAIL = "CATEGORIES_EDIT_FAIL";
